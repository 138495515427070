import { OrderState, PurchaseOrderDetailInfo } from '@carsayo/types';
import { useMemo } from 'react';
import ReactHtmlParser from 'react-html-parser';
import download from 'utils/download';

export function Dealer_EstimateFile({
  state,
  purchase,
}: {
  state: OrderState;
  purchase: PurchaseOrderDetailInfo;
}) {
  /** 이 페이지에서만 정의하여 사용합니다. */
  const orderCaseType:
    | '슈퍼카 리스'
    | '슈퍼카 렌트'
    | '리스'
    | '렌트'
    | '일시불'
    | '할부'
    | '' = useMemo(() => {
    if (!purchase) return '';

    if (purchase.isSuperCar) {
      if (purchase.purchaseInfo.method === '렌트') return '슈퍼카 렌트';
      if (purchase.purchaseInfo.method === '리스') return '슈퍼카 리스';
    }

    if (purchase.purchaseInfo.method === '렌트') return '렌트';
    if (purchase.purchaseInfo.method === '리스') return '리스';
    if (purchase.purchaseInfo.method === '일시불') return '일시불';
    if (purchase.purchaseInfo.method === '할부') return '할부';

    return '';
  }, [purchase]);

  const fileName: string = useMemo(() => {
    {
      if (orderCaseType === '슈퍼카 렌트' || orderCaseType === '슈퍼카 리스')
        return '고객 구매 계약서';
    }
    {
      if (orderCaseType === '렌트' || orderCaseType === '리스')
        return '타사 견적서';
    }
    {
      if (orderCaseType === '일시불' || orderCaseType === '할부')
        return '고객 구매 견적서';
    }
    return '';
  }, [orderCaseType]);

  return (
    <div className='w-full flex flex-col gap-4 justify-start items-start py-2'>
      <div className='flex flex-col gap-1'>
        <p className='text-[#767676] min-w-[23vw]'>{fileName}</p>
        {state === 'submitted' && (
          <div className='text-[14px]'>
            입찰 전{' '}
            <span className='font-semibold text-blue-600'>{fileName}</span>를 꼭
            확인해 주세요!
          </div>
        )}
      </div>
      <div className='text-[#111111] pb-4 w-full'>
        <div className='whitespace-pre-wrap w-full text-[#111111] bg-[#F7F7FB] p-4 flex flex-col gap-[12px] justify-start items-start'>
          {purchase.isSuperCar !== true &&
            purchase?.otherCompanyEstimateContent && (
              <span className='w-full'>
                {ReactHtmlParser(purchase.otherCompanyEstimateContent)}
              </span>
            )}
          {purchase?.otherCompanyEstimateFile && (
            <div
              className='w-full cursor-pointer flex h-12 items-center justify-center border border-[#111111] bg-white'
              onClick={
                purchase.otherCompanyEstimateFile
                  ? () => {
                      purchase.otherCompanyEstimateFile !== null
                        ? download(purchase.otherCompanyEstimateFile)
                        : null;
                    }
                  : () => {}
              }
            >
              {purchase.otherCompanyEstimateFile.name}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
