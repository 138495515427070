import {
  CarColor,
  CarOption,
  PurchaseOrderDTO,
  WholeSellingCar,
} from '@carsayo/types';
import { getCarColor, getCarOption, getWholeSellingCar } from 'apis/car';
import { updatePurchase } from 'apis/purchase';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

interface PurchaseCatalogue {
  color?: {
    gradeId: number;
    data: CarColor[];
  };
  option?: { gradeId: number; data: CarOption[] };
}
interface SetPurchaseCatalogue {
  /** 해당 모델 내 색상, 옵션 리스트를 카탈로그에 넣습니다 */
  gradeId?: number | null;
}

interface PurchaseState {
  isPending: boolean;
  wholeSellingCar?: WholeSellingCar[];
  purchaseOrderDTO?: PurchaseOrderDTO;
  /** 차량 카탈로그 데이터
   * @description 이 데이터를 통해 회원이 차량을 선택할 수 있게 합니다
   */
  catalogue?: PurchaseCatalogue;
}
interface PurchaseAction {
  setWholeSellingCar: () => void;
  setCatalogue: (params: SetPurchaseCatalogue) => void;
  setPurchaseOrderDTO: (
    purchaseOrderDTO: PurchaseOrderDTO,
    option?: { initial?: boolean },
  ) => void;
  resetPurchaseStore: () => void;
}

const usePurchaseStore = create<PurchaseState & PurchaseAction>()(
  devtools(
    immer((set, get) => ({
      isPending: false,
      wholeSellingCar: undefined,
      purchaseOrderDTO: undefined,
      catalogue: undefined,
      purchaseType: undefined,

      setWholeSellingCar: async () => {
        const currentData = get().wholeSellingCar;
        if (currentData) return;

        const data = await getWholeSellingCar();
        set((state) => {
          state.wholeSellingCar = data;
        });

        return;
      },
      setCatalogue: async (params: SetPurchaseCatalogue) => {
        const currentCatalogue = get().catalogue;

        const updatedCatalogue: PurchaseCatalogue = {
          color: params.gradeId
            ? {
                gradeId: params.gradeId,
                data: (await getCarColor(String(params.gradeId))).filter(
                  (el) => {
                    return !!el.image_url;
                  },
                ),
              }
            : params.gradeId === null
              ? undefined
              : currentCatalogue?.color,
          option: params.gradeId
            ? {
                gradeId: params.gradeId,
                data: await getCarOption(String(params.gradeId)),
              }
            : params.gradeId === null
              ? undefined
              : currentCatalogue?.option,
        };
        set((state) => {
          state.catalogue = updatedCatalogue;
        });
      },

      setPurchaseOrderDTO: async (
        purchaseOrderDTO: PurchaseOrderDTO,
        option?: { initial?: boolean },
      ) => {
        set((state) => {
          state.isPending = true;
        });

        const updatedPurchase = await updatePurchase(purchaseOrderDTO).catch(
          (error) => {
            set((state) => {
              state.isPending = false;
            });
            throw error;
          },
        );
        if (!updatedPurchase) return;

        set((state) => {
          state.isPending = false;
          state.purchaseOrderDTO = updatedPurchase;
        });

        // 차량 상세등급 선택 시 카탈로그 갱신
        if (purchaseOrderDTO.update?.carGradeId) {
          if (purchaseOrderDTO.update.carGradeId !== undefined) {
            if (
              get().catalogue?.color?.gradeId !==
              purchaseOrderDTO.update.carGradeId
            ) {
              await get().setCatalogue({
                gradeId: purchaseOrderDTO.update.carGradeId,
              });
            }
          }
        }

        // 시작 데이터를 가지고 카탈로그 갱신
        if (option?.initial) {
          if (updatedPurchase.orderData?.carGrade !== undefined) {
            if (
              get().catalogue?.color?.gradeId !==
              updatedPurchase.orderData.carGrade.id
            ) {
              await get().setCatalogue({
                gradeId: updatedPurchase.orderData.carGrade.id,
              });
            }
          }
        }
      },
      resetPurchaseStore: () => {
        set((state) => {
          state.isPending = false;
          state.purchaseOrderDTO = undefined;
          state.catalogue = undefined;
        });
      },
    })),
  ),
);

export default usePurchaseStore;
