import {
  CarModel,
  PopularCarCategory,
  InterestCarInfo,
  CarColor,
  CarGrade,
  CarOption,
  CarTrim,
  CarTrimIncludeGrade,
  GetCarModelDTO,
  GetUsedCarInfoDTO,
  SearchedUsedCarInfo,
  UpdateInterestCarDTO,
  WholeSellingCar,
} from '@carsayo/types';
import { api } from 'apis';
import { AxiosError } from 'axios';

export const getCarModel = async (reqData: GetCarModelDTO) => {
  const response = await api.get<Array<CarModel>>(`/car/data/model`, {
    params: reqData,
  });
  return response.data;
};

export const getCarTrim = async (
  carModelId: string,
): Promise<Array<CarTrim>> => {
  const response = await api.get(`/car/data/trim?carModelId=${carModelId}`);
  return response.data;
};

export const getCarTrimIncludeGrade = async (
  carModelId: string,
): Promise<Array<CarTrimIncludeGrade>> => {
  const response = await api.get(
    `/car/data/trim/includeGrade?carModelId=${carModelId}`,
  );
  return response.data;
};

export const getWholeSellingCar = async (): Promise<Array<WholeSellingCar>> => {
  const response = await api.get('/car/data/sellingCar');
  return response.data;
};

export const getCarGrade = async (
  carTrimId: string,
): Promise<Array<CarGrade>> => {
  const response = await api.get(`/car/data/grade?carTrimId=${carTrimId}`);
  return response.data;
};

export const getCarColor = async (
  carGradeId: string,
): Promise<Array<CarColor>> => {
  const response = await api.get(`/car/data/color?carGradeId=${carGradeId}`);
  return response.data;
};

export const getCarOption = async (
  carGradeId: string,
): Promise<Array<CarOption>> => {
  const response = await api.get(`/car/data/option?carGradeId=${carGradeId}`);
  return response.data;
};

/** 쿠콘 중고차 정보 조회 */
export const getCooconCarInfo = async (
  reqData: GetUsedCarInfoDTO,
): Promise<SearchedUsedCarInfo> => {
  try {
    const { data } = await api.get<SearchedUsedCarInfo>(
      `/coocon-usedcar/info`,
      {
        params: reqData,
      },
    );
    return data;
  } catch (e: any) {
    const error: AxiosError = e;
    throw error;
  }
};

/** 인기차량 리스트 */
export const getPopularCarList = async () => {
  try {
    const { data } = await api.get<PopularCarCategory[]>(`/car/popular`);
    return data;
  } catch (e: any) {
    const error: AxiosError = e;
    throw error;
  }
};
/** 관심차량 등록 */
export const selectInterestCar = async (carId: number, isInterest: boolean) => {
  try {
    const { data } = await api.post<UpdateInterestCarDTO>(`/car/interest/set`, {
      carModelId: carId,
      isInterest: isInterest,
    });
    return data;
  } catch (e: any) {
    const error: AxiosError = e;
    throw error;
  }
};
/** 내 관심차량 리스트 */
export const getInterestCarList = async () => {
  try {
    const { data } = await api.get<InterestCarInfo[]>(`/car/interest/list`);
    return data;
  } catch (e: any) {
    const error: AxiosError = e;
    throw error;
  }
};
