/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button } from 'components/ui/button';
import {
  ChangeEvent,
  RefObject,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import useSystemStore from 'store/useSystemStore';
import usePurchaseStore from './store';
import useFileUploader from 'store/useFileUploader';
import download from 'utils/download';
import { submitPurchase } from 'apis/purchase';
import Confirm from 'components/ui/confirm';
import getPrice_PurchaseOrderDTO from 'utils/price/getPrice_PurchaseOrderDTO';
import { CarsayoToast } from 'components/common/v2/CarsayoToast';
import { ga4_event } from 'hooks/ga4';
import { cn } from 'utils';

type ErrorData = {
  otherCompanyEstimateFile: boolean;
};

function SuperCarEstimateInput({ errorData }: { errorData: ErrorData }) {
  const { fileUpload } = useFileUploader();
  const { purchaseOrderDTO, setPurchaseOrderDTO } = usePurchaseStore();

  const uploadedFile = useMemo(() => {
    return purchaseOrderDTO?.orderData?.otherCompanyEstimateFile;
  }, [purchaseOrderDTO]);

  if (!purchaseOrderDTO) return <></>;

  return (
    <div className='mt-4 bg-[#F7F7FB] p-4'>
      <div className='text-base font-semibold'>
        구매 계약서를 업로드 해 주세요.
      </div>
      <div className='mt-3 text-[#767676]'>
        · 카사요는 [대출성 금융상품 판매대리·중개업자 등록증]을 보유한
        플랫폼으로 리스 및 장기렌트 서비스가 가능합니다.
      </div>
      <div className='text-[#767676]'>
        · 구매하실 계약서를 업로드 해 주시면 카사요 금융 카매니저님이 합리적인
        금융 서비스를 제안합니다.
      </div>
      <div
        className={cn(
          'mt-3 flex h-12 items-center gap-4 border bg-white px-4',
          errorData.otherCompanyEstimateFile === true && 'border-red-500',
        )}
      >
        <div className='h-4 w-4'>
          <img src='/assets/images/v2/file.svg' alt='' />
        </div>

        {uploadedFile ? (
          <div
            onClick={() => {
              download(uploadedFile);
            }}
          >
            {purchaseOrderDTO.orderData?.carMaker?.name} 계약서
          </div>
        ) : (
          <div>계약서 업로드</div>
        )}

        <div className='ml-auto'>
          {uploadedFile ? (
            <Button
              onClick={() => {
                setPurchaseOrderDTO({
                  id: purchaseOrderDTO.id,
                  type: purchaseOrderDTO.type,
                  update: {
                    otherCompanyEstimateFileId: null,
                  },
                });
              }}
              variant='outline'
              size='xs'
              className='w-auto'
            >
              제거
            </Button>
          ) : (
            <Button
              onClick={() => {
                fileUpload(
                  {
                    acceptFile: ['image', 'pdf'],
                    fileType: 'superCarContract',
                    fileName: `${purchaseOrderDTO.orderData?.carMaker?.name} 계약서`,
                    isPublic: true,
                  },
                  async (fileInfo, file) => {
                    if (!purchaseOrderDTO) return;
                    await setPurchaseOrderDTO({
                      id: purchaseOrderDTO.id,
                      type: purchaseOrderDTO.type,
                      update: {
                        otherCompanyEstimateFileId: fileInfo.id,
                      },
                    });
                  },
                );
              }}
              variant='outline'
              size='xs'
              className='w-auto'
            >
              업로드
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}

function OtherCompanyEstimateInput({
  textRef,
  errorData,
}: {
  textRef: RefObject<HTMLTextAreaElement>;
  errorData: ErrorData;
}) {
  const { fileUpload } = useFileUploader();
  const { purchaseOrderDTO, setPurchaseOrderDTO } = usePurchaseStore();

  const uploadedFile = useMemo(() => {
    return purchaseOrderDTO?.orderData?.otherCompanyEstimateFile;
  }, [purchaseOrderDTO]);

  const [textCount, setTextCount] = useState<number>(0);

  const handleTextAreaChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setTextCount(
      /** 한글은 3byte, 영문은 1byte */
      e.target.value.replace(/[0-\x7f]|([0-\u07ff]|(.))/g, '$&$1$2').length,
    );
  };

  if (!purchaseOrderDTO) return <></>;

  return (
    <div className='mt-4 bg-[#F7F7FB] p-4'>
      <div className='text-base font-semibold'>
        이미 보유하고 계신 견적서가 있으신가요?
      </div>
      <div className='mt-3 text-[#767676]'>
        · 카사요는 [대출성 금융상품 판매대리·중개업자 등록증]을 보유한
        플랫폼으로 더 좋은 조건의 견적을 직접 제공해 드릴 수 있습니다.
      </div>
      <div className='text-[#767676]'>
        · 받아보신 견적서와 혜택 내용을 함께 기재해 주시면 더욱 상세한 견적을
        제안해 드리겠습니다.
      </div>
      <div className='mt-3'>타사 혜택 내용 (선택)</div>
      <div className='relative mt-2'>
        <textarea
          ref={textRef}
          onChange={handleTextAreaChange}
          className='h-[158px] w-full resize-none rounded border bg-white p-4'
          placeholder='제공받기로 한 추가 혜택을 알려주세요.'
        />
        <div className='absolute bottom-4 right-4 text-[#767676]'>
          {textCount}/200
        </div>
      </div>

      <div
        className={cn(
          'mt-3 flex h-12 items-center gap-4 border bg-white px-4',
          errorData.otherCompanyEstimateFile === true && 'border-red-500',
        )}
      >
        <div className='h-4 w-4'>
          <img src='/assets/images/v2/file.svg' alt='' />
        </div>

        {uploadedFile ? (
          <div
            onClick={() => {
              download(uploadedFile);
            }}
          >
            타사 견적서
          </div>
        ) : (
          <div>견적서 업로드 (선택)</div>
        )}
        <div className='ml-auto'>
          {uploadedFile ? (
            <Button
              onClick={() => {
                setPurchaseOrderDTO({
                  id: purchaseOrderDTO.id,
                  type: purchaseOrderDTO.type,
                  update: {
                    otherCompanyEstimateFileId: null,
                  },
                });
              }}
              variant='outline'
              size='xs'
              className='w-auto'
            >
              제거
            </Button>
          ) : (
            <Button
              onClick={() => {
                fileUpload(
                  {
                    acceptFile: ['image', 'pdf'],
                    fileType: 'otherCompanyEstimate',
                    fileName: '타사 견적서',
                    isPublic: true,
                  },
                  async (fileInfo, file) => {
                    if (!purchaseOrderDTO) return;
                    await setPurchaseOrderDTO({
                      id: purchaseOrderDTO.id,
                      type: purchaseOrderDTO.type,
                      update: {
                        otherCompanyEstimateFileId: fileInfo.id,
                      },
                    });
                  },
                );
              }}
              variant='outline'
              size='xs'
              className='w-auto'
            >
              업로드
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}

/** 할부 / 일시불일 경우 견적서 파일이 필수 조건이 됩니다. */
function NewCarEstimateInput({
  textRef,
  errorData,
}: {
  textRef: RefObject<HTMLTextAreaElement>;
  errorData: ErrorData;
}) {
  const { fileUpload } = useFileUploader();
  const { purchaseOrderDTO, setPurchaseOrderDTO } = usePurchaseStore();

  const uploadedFile = useMemo(() => {
    return purchaseOrderDTO?.orderData?.otherCompanyEstimateFile;
  }, [purchaseOrderDTO]);

  const [textCount, setTextCount] = useState<number>(0);

  const handleTextAreaChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setTextCount(
      /** 한글은 3byte, 영문은 1byte */
      e.target.value.replace(/[0-\x7f]|([0-\u07ff]|(.))/g, '$&$1$2').length,
    );
  };

  if (!purchaseOrderDTO) return <></>;

  return (
    <div className='mt-4 bg-[#F7F7FB] p-4'>
      <div className='text-base font-semibold'>
        이미 보유하고 계신 견적서가 있으신가요?
      </div>
      <div className='mt-3 text-[#767676]'>
        · 카사요는 [대출성 금융상품 판매대리·중개업자 등록증]을 보유한
        플랫폼으로 더 좋은 조건의 견적을 직접 제공해 드릴 수 있습니다.
      </div>
      <div className='text-[#767676]'>
        · 받아보신 견적서와 혜택 내용을 함께 기재해 주시면 더욱 상세한 견적을
        제안해 드리겠습니다.
      </div>
      <div className='mt-3'>타사 혜택 내용 (선택)</div>
      <div className='relative mt-2'>
        <textarea
          ref={textRef}
          onChange={handleTextAreaChange}
          className='h-[158px] w-full resize-none rounded border bg-white p-4'
          placeholder='제공받기로 한 추가 혜택을 알려주세요.'
        />
        <div className='absolute bottom-4 right-4 text-[#767676]'>
          {textCount}/200
        </div>
      </div>

      <div
        className={cn(
          'mt-3 flex h-12 items-center gap-4 border bg-white px-4',
          errorData.otherCompanyEstimateFile === true && 'border-red-500',
        )}
      >
        <div className='h-4 w-4'>
          <img src='/assets/images/v2/file.svg' alt='' />
        </div>

        {uploadedFile ? (
          <div
            onClick={() => {
              download(uploadedFile);
            }}
          >
            {purchaseOrderDTO.orderData?.carMaker?.name} 견적서
          </div>
        ) : (
          <div>견적서 업로드</div>
        )}
        <div className='ml-auto'>
          {uploadedFile ? (
            <Button
              onClick={() => {
                setPurchaseOrderDTO({
                  id: purchaseOrderDTO.id,
                  type: purchaseOrderDTO.type,
                  update: {
                    otherCompanyEstimateFileId: null,
                  },
                });
              }}
              variant='outline'
              size='xs'
              className='w-auto'
            >
              제거
            </Button>
          ) : (
            <Button
              onClick={() => {
                fileUpload(
                  {
                    acceptFile: ['image', 'pdf'],
                    fileType: 'otherCompanyEstimate',
                    fileName: `${purchaseOrderDTO.orderData?.carMaker?.name} 견적서`,
                    isPublic: true,
                  },
                  async (fileInfo, file) => {
                    if (!purchaseOrderDTO) return;
                    await setPurchaseOrderDTO({
                      id: purchaseOrderDTO.id,
                      type: purchaseOrderDTO.type,
                      update: {
                        otherCompanyEstimateFileId: fileInfo.id,
                      },
                    });
                  },
                );
              }}
              variant='outline'
              size='xs'
              className='w-auto'
            >
              업로드
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}

export default function Estimate() {
  const navigate = useNavigate();

  const { setTopTabbarTitle, setLoadingDim } = useSystemStore();
  const { purchaseOrderDTO, setPurchaseOrderDTO } = usePurchaseStore();

  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [textCount, setTextCount] = useState<number>(0);

  const textInputRef1 = useRef<HTMLTextAreaElement>(null);

  const OtherCompanyEstimateInputRef = useRef<HTMLTextAreaElement>(null);

  const [errorData, setErrorData] = useState<ErrorData>({
    otherCompanyEstimateFile: false,
  });

  const checkValid = (): boolean => {
    const errors = {
      otherCompanyEstimateFile: false,
    };

    /** 슈퍼카 매니저는 견적서 업로드가 필수입니다. */
    if (
      (orderCaseType === '슈퍼카 렌트' || orderCaseType === '슈퍼카 리스') &&
      !purchaseOrderDTO?.orderData?.otherCompanyEstimateFile
    ) {
      errors.otherCompanyEstimateFile = true;
      CarsayoToast.error('구매 계약서를 업로드 해 주세요.');
    } else if (
      (orderCaseType === '일시불' || orderCaseType === '할부') &&
      !purchaseOrderDTO?.orderData?.otherCompanyEstimateFile
    ) {
      errors.otherCompanyEstimateFile = true;
      CarsayoToast.error('견적서 파일을 업로드 해 주세요.');
    }

    setErrorData(errors);

    return !Object.values(errors).some(Boolean);
  };

  const submit = async () => {
    if (!purchaseOrderDTO) return;

    setLoadingDim(true);

    setTimeout(async () => {
      try {
        ga4_event({
          category: 'order_request',
          action: '구매 신청 접수',
          label: purchaseOrderDTO.id,
        });

        await setPurchaseOrderDTO({
          id: purchaseOrderDTO.id,
          type: purchaseOrderDTO.type,
          update: {
            additionalRequest: textInputRef1.current
              ? textInputRef1.current.value
              : undefined,
            otherCompanyEstimateContent: OtherCompanyEstimateInputRef.current
              ? OtherCompanyEstimateInputRef.current.value
              : undefined,
          },
        });
        await submitPurchase(purchaseOrderDTO);
        goNext();
      } catch (e) {
        setLoadingDim(false);
      }
      setLoadingDim(false);
    }, 3000);
  };

  const setData = async () => {
    if (!purchaseOrderDTO) return;
    await setPurchaseOrderDTO({
      id: purchaseOrderDTO.id,
      type: purchaseOrderDTO.type,
      update: {
        progress: 90,
        currentPath: '/purchase/estimate',
      },
    });
  };

  const goBack = () => {
    navigate('/purchase/method', { replace: true });
  };
  const goNext = () => {
    navigate('/purchase/complete', { replace: true });
  };

  /** 이 페이지에서만 정의하여 사용합니다. */
  const orderCaseType:
    | '슈퍼카 리스'
    | '슈퍼카 렌트'
    | '리스'
    | '렌트'
    | '일시불'
    | '할부'
    | '' = useMemo(() => {
    if (!purchaseOrderDTO) return '';

    if (purchaseOrderDTO?.orderData?.isSuperCar === true) {
      if (purchaseOrderDTO.orderData.additionalInfo.method === '리스')
        return '슈퍼카 리스';
      if (purchaseOrderDTO.orderData.additionalInfo.method === '렌트')
        return '슈퍼카 렌트';
    }

    if (purchaseOrderDTO?.orderData?.additionalInfo.method === '리스')
      return '리스';
    if (purchaseOrderDTO?.orderData?.additionalInfo.method === '렌트')
      return '렌트';
    if (purchaseOrderDTO?.orderData?.additionalInfo.method === '할부')
      return '할부';
    if (purchaseOrderDTO?.orderData?.additionalInfo.method === '일시불')
      return '일시불';

    return '';
  }, [purchaseOrderDTO]);

  const purchasePrice: number = useMemo(() => {
    if (!purchaseOrderDTO) return 0;
    return getPrice_PurchaseOrderDTO(purchaseOrderDTO);
  }, [purchaseOrderDTO]);

  const handleTextAreaChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setTextCount(
      /** 한글은 3byte, 영문은 1byte */
      e.target.value.replace(/[0-\x7f]|([0-\u07ff]|(.))/g, '$&$1$2').length,
    );
  };

  useEffect(() => {
    window.native.onBackPressed = goBack;
  }, []);

  useLayoutEffect(() => {
    setTopTabbarTitle('견적 신청');
    setData();
  }, []);

  if (!purchaseOrderDTO || !orderCaseType) return <></>;

  return (
    <div className='flex flex-col h-full'>
      <div className='flex-auto overflow-y-scroll'>
        <div className='relative z-10 px-4 py-8 shadow-[0_5px_10px_0_rgba(0,0,0,.06)]'>
          <div className='text-xl font-semibold'>
            {purchaseOrderDTO.orderData?.carMaker?.name}{' '}
            {purchaseOrderDTO.orderData?.carModel?.name}
          </div>
          <div className='mt-1.5'>
            {purchaseOrderDTO.orderData?.carGrade?.name}
          </div>
          <div className='mx-auto mt-4 h-[120px] w-[186px]'>
            <img src={purchaseOrderDTO.orderData?.carModel?.image_url} alt='' />
          </div>
          {/* 차량 가격 안내 파트 */}
          {purchasePrice > 0 && (
            <div className='mt-4'>
              <div className='border-b pb-2'>
                <div className='flex items-center justify-between pb-2 pt-2'>
                  <div className='text-[#555555]'>차량 기본가</div>
                  <div>
                    {purchaseOrderDTO?.orderData?.carGrade?.price.toLocaleString()}
                    원
                  </div>
                </div>
                {purchaseOrderDTO?.orderData?.carColor?.price !== undefined &&
                  purchaseOrderDTO.orderData.carColor.price > 0 && (
                    <div className='flex items-center justify-between pb-2 pt-2'>
                      <div className='text-[#555555]'>색상 가격</div>
                      <div>
                        {purchaseOrderDTO?.orderData?.carColor?.price.toLocaleString()}
                        원
                      </div>
                    </div>
                  )}
                {purchaseOrderDTO?.orderData?.carOptionList &&
                  purchaseOrderDTO.orderData.carOptionList.length > 0 && (
                    <div className='flex items-center justify-between pb-2 pt-2'>
                      <div className='text-[#555555]'>옵션 가격</div>
                      <div>
                        {purchaseOrderDTO.orderData.carOptionList
                          .map((el) => {
                            return el.price;
                          })
                          .reduce((prev, curr) => {
                            return prev + curr;
                          })
                          .toLocaleString()}
                        원
                      </div>
                    </div>
                  )}
              </div>
              <div className='mt-4 flex items-center justify-between'>
                <div className='text-[#555555]'>총 예상 금액</div>
                {purchaseOrderDTO && (
                  <div className='font-semibold text-[#7273F9]'>
                    {purchasePrice.toLocaleString()}원
                  </div>
                )}
              </div>
              <div className='mt-4 text-[#767676]'>
                · 실거래가는 제조사 및 딜러사의 정책에 따리 달라질 수 있다는 점
                참고 부탁드립니다.
              </div>
            </div>
          )}
        </div>

        {/* 견적서 업로드: orderCaseType에 따라 경우 구분
          1. [리스/렌트 슈퍼카 주문]일 경우 [구매 계약서 업로드]가 됩니다.
            1-1. [구매 계약서 업로드]는 주문할 때 필수 조건이 됩니다.
          2. [리스/렌트 비 슈퍼카 주문]일 경우 [타사 견적서 업로드] 및 [타사 혜택 내용]이 됩니다.
          3. [일시불, 할부]일 경우 [계약서 업로드]가 됩니다.
          4. [otherCompanyEstimateFile], [otherCompanyEstimateContent]에 파일 및 혜택 내용을 저장합니다.
        */}
        <div className='px-4 pt-2'>
          {(orderCaseType === '슈퍼카 렌트' ||
            orderCaseType === '슈퍼카 리스') && (
            <SuperCarEstimateInput errorData={errorData} />
          )}

          {(orderCaseType === '리스' || orderCaseType === '렌트') && (
            <OtherCompanyEstimateInput
              errorData={errorData}
              textRef={OtherCompanyEstimateInputRef}
            />
          )}

          {(orderCaseType === '할부' || orderCaseType === '일시불') && (
            <NewCarEstimateInput
              errorData={errorData}
              textRef={OtherCompanyEstimateInputRef}
            />
          )}
        </div>

        {/* 추가 요청사항 입력 */}
        <div className='px-4 py-4'>
          <div>요청내용 (선택)</div>
          <div className='relative mt-2'>
            <textarea
              ref={textInputRef1}
              onChange={handleTextAreaChange}
              className='h-[158px] w-full resize-none rounded border bg-white p-4'
              placeholder='할인금액, 출고 서비스, 맞춤 조건 시 계약 여부 등 매니저님에게 전달하고 싶은 내용을 적어주세요.'
              maxLength={200}
            />
            <div className='absolute bottom-4 right-4 text-[#767676]'>
              {textCount}/200
            </div>
          </div>
        </div>
      </div>
      <div className='flex-none grid grid-cols-2 bg-[#F7F7FB] px-4 py-6'>
        <Button
          variant='outline'
          className='rounded-r-none bg-white'
          onClick={goBack}
        >
          이전
        </Button>
        <Button
          className='rounded-l-none'
          disabled={!orderCaseType}
          onClick={() => {
            if (checkValid() === false) return;
            setIsConfirmOpen(true);
          }}
        >
          주문 제출
        </Button>
      </div>
      <Confirm
        isOpen={isConfirmOpen}
        setIsOpen={setIsConfirmOpen}
        isCloseButton={true}
        onConfirm={submit}
        title='주문 제출하기'
        desc={'정말로 주문을 진행하시겠습니까?'}
      ></Confirm>
    </div>
  );
}
