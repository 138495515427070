import { Button } from 'components/ui/button';
import { useEffect, useLayoutEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import useSystemStore from 'store/useSystemStore';
import usePurchaseStore from './store';
import { cn } from 'utils';
import { CarsayoToast } from 'components/common/v2/CarsayoToast';

export default function Model() {
  const navigate = useNavigate();

  const { setTopTabbarTitle } = useSystemStore();
  const { wholeSellingCar, purchaseOrderDTO, setPurchaseOrderDTO } =
    usePurchaseStore();

  const setData = async () => {
    if (!purchaseOrderDTO) return;
    await setPurchaseOrderDTO({
      id: purchaseOrderDTO.id,
      type: purchaseOrderDTO.type,
      update: {
        progress: 10,
        currentPath: '/purchase/model',
        carTrimId: null,
        carGradeId: null,
        carColorId: null,
        isNotSelectColor: null,
        carOptionIdList: [],
        additionalInfo: purchaseOrderDTO.orderData?.additionalInfo
          ? {
              method: purchaseOrderDTO.orderData.additionalInfo.method,
              렌트: {},
              리스: {},
              일시불: {},
              할부: {},
            }
          : null,
        additionalRequest: null,
        otherCompanyEstimateFileId: null,
      },
    });
  };

  const goBack = () => {
    navigate(`/purchase/brand?purchaseType=${purchaseOrderDTO?.type}`, {
      replace: true,
    });
  };
  const goNext = () => {
    navigate('/purchase/detail', { replace: true });
  };

  const targetMaker = useMemo(() => {
    if (!wholeSellingCar) return undefined;

    return wholeSellingCar.find((el) => {
      return el.id === purchaseOrderDTO?.orderData?.carMaker?.id;
    });
  }, [wholeSellingCar, purchaseOrderDTO]);

  useEffect(() => {
    window.native.onBackPressed = goBack;
  }, []);

  useLayoutEffect(() => {
    setTopTabbarTitle('모델 선택');
    setData();
  }, []);

  return (
    <div className='flex flex-col h-full'>
      <div className='flex-none pt-6 px-4 text-lg font-semibold'>모델 선택</div>
      <div className='flex flex-col flex-auto px-4 pb-4 mt-4 space-y-8 overflow-y-scroll'>
        {targetMaker &&
          targetMaker.car_model.map((el, index) => (
            <div
              key={index}
              className={cn(
                'flex items-center justify-between rounded-md border bg-[#F7F7FB] p-4',
                purchaseOrderDTO?.orderData?.carModel?.id === el.id
                  ? 'border-primary'
                  : '',
              )}
              onClick={async () => {
                if (!purchaseOrderDTO) return;
                setPurchaseOrderDTO({
                  id: purchaseOrderDTO.id,
                  type: purchaseOrderDTO.type,
                  update: {
                    carModelId: el.id,
                  },
                });
              }}
            >
              <div>
                <div
                  className={cn(
                    'text-desc text-[#555555]',
                    el.attribute.isPreorder === true && 'font-semibold',
                  )}
                >
                  {el.attribute.isPreorder === true
                    ? '사전예약'
                    : purchaseOrderDTO?.orderData?.carMaker?.name}
                </div>
                <div className='mt-0.5 text-title font-semibold'>{el.name}</div>

                {el.price.max > 0 && (
                  <div className='mt-1.5 text-desc'>
                    {el.price.min !== el.price.max && (
                      <>
                        {Math.floor(el.price.min / 10000).toLocaleString()} ~{' '}
                      </>
                    )}
                    {Math.floor(el.price.max / 10000).toLocaleString()}만원
                  </div>
                )}
              </div>
              <div className='h-[62px] w-[96px]'>
                <img src={el.image_url} alt='' />
              </div>
            </div>
          ))}

        {purchaseOrderDTO?.orderData?.isSuperCar === true && (
          <div className='flex-none'>
            <div className='rounded-md border p-4 bg-gray-50'>
              계약하신 모델이 없다면
              <br />
              <a
                href='mailto:info@carsayo.net'
                className='text-blue-500 font-medium underline'
              >
                info@carsayo.net
              </a>
              으로 문의해 주세요.
            </div>
          </div>
        )}
      </div>

      <div className='flex-none grid grid-cols-2 bg-[#F7F7FB] px-4 py-6'>
        <Button
          variant='outline'
          className='rounded-r-none bg-white'
          onClick={goBack}
        >
          이전
        </Button>
        <Button
          className='rounded-l-none'
          onClick={async () => {
            if (purchaseOrderDTO?.orderData?.carModel) {
              goNext();
            } else {
              CarsayoToast.error('모델이 선택되지 않았습니다');
            }
          }}
        >
          다음
        </Button>
      </div>
    </div>
  );
}
